import React, {useEffect, useState} from "react"
import parse from 'html-react-parser'
import Img from 'gatsby-image';
import {Link} from 'gatsby';
import { navigate } from "gatsby"
import { useInView } from 'react-intersection-observer';
import { Helmet } from "react-helmet";
import "../sass/pages/_case_studies-archive.scss";
import Seo from '../components/seo'
import { SchemaLinksToAbsolute } from "../helpers/SchemaLinksToAbsolute";
import { graphql } from 'gatsby'

const CaseStudies = ({ data, pageContext }) => {

  const page = data.wpPage;
  const originalPosts = data.allWpCaseStudy;
  const cats = data.allWpCaseCat;
  const {totalPages, pageNumber} = pageContext;
  const [isActive, setIsActive] = useState('');
  const seoTitle = data.wpPage.seo.title
  const seoDesc = data.wpPage.seo.metaDesc

  // const posts = data.allWpCaseStudy.edges;
  let newSortPosts = originalPosts
  // const x = data.projects.edges
  
  //// Reorder theProjects Array to put element with menuOrder 'null' at start
  const  [firstItem, setFirstItem] = useState();
  const  [posts, setPosts] = useState(originalPosts);
  
 
  useEffect(()=> {
    data.allWpCaseStudy.edges.forEach(project => {

      if(project.node.menuOrder === null){
        //console.log(project.node.title)
        setFirstItem(project)
      }
    })
  })

  const reorderArray = () => {
    //console.log(originalPosts)
    newSortPosts.edges.pop()
    newSortPosts.edges.unshift(firstItem)
  }

  useEffect(() => {
    //console.log(originalPosts)
    if(firstItem !== undefined){
      if(originalPosts.edges[0].node.menuOrder !== null){
        reorderArray()
      }
      setPosts(newSortPosts)
      // setAllProjects(posts)
    }
  },[firstItem])

  const classNames = {};
  posts.edges.map((post,i) => {

    if(post.node.acf_caseStudyFields.backgroundType === 'png-bg'){
      if(i%2 === 0){
        classNames[i] = 'left is_inview inview_check2 png-bg';
      }else{
        classNames[i] = 'right is_inview inview_check2 png-bg';
      }
    }else{
      if(i%2 === 0){
        classNames[i] = 'left is_inview inview_check2 cover';
      }else{
        classNames[i] = 'right is_inview inview_check2 cover';
      }
    }
    
  });

  useEffect(() => {
    if(typeof document !== 'undefined'){
      document.querySelector('body').setAttribute('data-bg-color', 'bg-white')
      document.querySelector('body').setAttribute('data-class', '')
      
      const currentPage = window.location.pathname.split("/").pop();
      setIsActive(currentPage);
    }
  }, [])

  const handleOnChange = (e) => {
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index]
    var option =  optionElement.getAttribute('data-slug');
    
    navigate(option)

  }

   
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });

  return (
    <>
    <Seo 
    title={seoTitle} 
    description={seoDesc} 
    date={data.wpPage.date}
    slug={'/'+data.wpPage.slug+'/'}
    robotsFollow={data.wpPage.seo.metaRobotsNofollow}
    robotsIndex={data.wpPage.seo.metaRobotsNoindex}
    canonicalUrl={data.wpPage.seo.canonical}
    />
     {data.wpPage.seo.schema.raw !== undefined ? 
        <SchemaLinksToAbsolute schemaRaw={data.wpPage.seo.schema.raw} /> 
      : "" }
     <Helmet>
        <script type="application/ld+json">
          {
            `{
              "@context":"https://schema.org",
              "@graph":[
                  {
                    "@type":"CollectionPage",
                    "@id":"${process.env.GATSBY_SITE_URL}/case-studies/${pageNumber !== 1 ? 'page/'+pageNumber : ''}#webpage",
                    "url":"${process.env.GATSBY_SITE_URL}/case-studies/${pageNumber !== 1 ? 'page/'+pageNumber+'/' : ''}",
                    "name":"Online Marketing Blog | Edge45\u00ae",
                    "isPartOf":{
                        "@id":"${process.env.GATSBY_SITE_URL}/#website"
                    },
                    "description":"Edge45\u00ae is passionate about all things regarding online marketing. Read some of the things we have written here on our marketing blog.",
                    "breadcrumb":{
                        "@id":"${process.env.GATSBY_SITE_URL}/case-studies/${pageNumber !== 1 ? 'page/'+pageNumber : ''}#breadcrumb"
                    },
                    "inLanguage":"en-GB",
                    "potentialAction":[
                        {
                          "@type":"ReadAction",
                          "target":[
                              "${process.env.GATSBY_SITE_URL}/case-studies/${pageNumber !== 1 ? 'page/'+pageNumber+'/' : ''}"
                          ]
                        }
                    ]
                  }
              ]
            }`
          }
        </script>
      </Helmet>
      <div className="case_studies"  ref={ref} >
        <section className={`intro ${inView ? 'is_inview' : ''}`}>
          <div className="wrapper">
            <div className="left">
              <h1>Case Studies</h1>
              <section className="filters">
                <ul>
                  <li className="active"><button data-cat="*">All Case Studies</button></li>
                  {cats.nodes.map((cat, index) => {
                    if(cat.count != null){
                      return(<li key={cat.slug+"_list_"+index}><Link to={'/case-studies/'+cat.slug+'/'} className={cat.slug}>{cat.name}</Link></li>)
                    }                   
                  })}
                </ul>

                <select name="filters" onChange={(e) => handleOnChange(e)} defaultValue={isActive} >
                  <option data-cat="All Case Studies"  data-slug={'/case-studies/'} selected>All Case Studies</option> 		
                  {cats.nodes.map((cat, index) => {
                    if(cat.count != null){
                      return(  <option key={cat.slug+"_select_"+index} data-cat={cat.name} data-slug={'/case-studies/'+cat.slug+'/'}>{cat.name}</option>       )
                    }                   
                  })}	     			
                </select>
              </section>
            </div>
            <div className="right">
              <div className="text-area">{parse(page.acf_introText.introText)}</div>
            </div>
          </div>
        </section>
        
        <section className={`recent ${inView ? 'is_inview' : ''}`}>
          <div className="wrapper">
            <div className="wrapper--inner">
              {
                posts.edges.map((post,i) => {
                  if(i < 4){
                    return(
                      <article 
                        data-id="" 
                        data-cat="" 
                        className={
                          classNames[i]
                        }
                      >
                        <Link to={post.node.link}>		
                        
                          <div className="image-wrap">
                            <div className="tint"></div>
                            {post?.node?.acf_caseStudyFields?.backgroundImage?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
                            <Img fluid={post?.node?.acf_caseStudyFields?.backgroundImage?.localFile?.childImageSharp?.fluid } alt={post?.node?.acf_caseStudyFields?.backgroundImage?.altText} className="bg-cover BG bgStretch" />
                          </div>
                          <div className="info">
                            <div className="label">{post.node.acf_caseStudyFields.companyName}</div>
                            <h2>{post.node.excerpt.replace(/(<([^>]+)>)/gi, "")}</h2>
                          </div>
                        </Link>
                      </article>
                    )
                  }
                  i++;
                })
              }
            </div>
          </div>
        </section>
        
        
        <section  className={`additional_case_studies ${inView ? 'is_inview' : ''}`}>
          <div className="wrapper">
          {
          posts.edges.length > 4 ? 
            <div className="label title">Additional<span className="dynamicName"></span> Case Studies</div>
            : ""
          }
            <div className={`the_posts ${totalPages <= 1 ? 'no_pagination' : ""}`}>
            {
                posts.edges.map((post,i) => {
                  if(i >= 4){
                    return(
                      <article 
                        data-id="" 
                        data-cat="" 
                        className={
                         'post ' + classNames[i]
                        }
                      >
                        <Link to={post.node.link}>			

                          <div className="image-wrap">
                            <div className="tint"></div>
                            {post?.node?.acf_caseStudyFields?.backgroundImage?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
                            <Img fluid={post?.node?.acf_caseStudyFields?.backgroundImage?.localFile?.childImageSharp?.fluid } className="bg-cover BG bgStretch" />
                          </div>
                          <div className="info">
                            <div className="label">{post.node.acf_caseStudyFields.companyName}</div>
                            <h2>{post.node.excerpt.replace(/(<([^>]+)>)/gi, "")}</h2>
                          </div>
                        </Link>
                      </article>
                    )
                  }
                  i++;
                })
              }

              {totalPages  > 1 ?
                <section className="pagination">
                  <div className="wp-pagenavi" role="navigation">
                      {pageNumber !== 1 ?
                          <Link className="previouspostslink" rel="previous" to={pageNumber === 2 ? '/case-studies/': `/case-studies/page/${pageNumber-1}/`} >Prev</Link>
                      : ""
                      }
                      {Array.from({ length: totalPages }, (_, i) => (
                          i === pageNumber - 1 ? 
                              <span aria-current="page" className="current">{i + 1}</span>
                          : 
                          <Link
                          className="page larger"
                          to={`${i === 0 ? '/case-studies' : '/case-studies/page/'+ (i + 1)}/`}
                          >
                          {i + 1}
                          </Link>
                      ))}
                      {pageNumber !== totalPages ?
                          <Link className="nextpostslink" rel="next" to={`/case-studies/page/${pageNumber+1}/`}>Next</Link>
                      : ""
                      }
                      
                  </div>
                </section>
                : ""
              }
            </div>
          </div>
        </section>	
      </div>
    </>
  )
}

export default CaseStudies

export const query = graphql`

query CaseStudyArchive($offset: Int!, $postsPerPage: Int!) {
  wpPage(id: {eq: "cG9zdDoxMTcy"}) {
    id
    title
    slug
    date
    seo {
      title
      metaDesc
      metaRobotsNofollow
      metaRobotsNoindex
      canonical
      schema {
        raw
      }
    }
    acf_introText {
      introText
    }
  }
  allWpCaseCat(
    sort: { order: ASC, fields: id }
  ){
    nodes {
      slug
      name
      link
      count
    }
  }
  allWpCaseStudy(
    sort: {order: ASC, fields: menuOrder}
    filter: {hideCaseStudyFromArchive: {hideThisCaseStudyFromArchive: {ne: true}}}
    limit: $postsPerPage
    skip: $offset
  ) {
    edges {
      node {
        menuOrder
        id
        title
        excerpt
        link
        acf_caseStudyFields {
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          backgroundType
          caseStudyTitle
          colour
          companyName
        }
      }
    }
  }
}

    
`